import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import logo from '../assets/Kongi1-08.png';
import twitter from '../assets/x.png';
import teleg from '../assets/tg.png';
import os from '../assets/os.png';
import partner1 from '../assets/pinksale.png';
import partner2 from '../assets/coinscope.png';
import partner3 from '../assets/marketWatch.png';
import partner4 from '../assets/dexToolse.png';
import partner5 from '../assets/bloomberg.png';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

const tweet = () => {
	window.open("https://x.com/kongi_token");
}

const telegL = () => {
	window.open("https://t.me/kongi_token");
}

const osL = () => {
	window.open("https://opensea.io/collection/kongi-academy");
}


class Footer extends Component {

    render() {
        return (

            <div class="boxWrap2Footer">

            <div class="footer">

                
            <div class="partners">
                    <img src={partner1}/>
                    <img src={partner3}/>
                    <img src={partner4}/>
                    <img src={partner5}/>
                </div>

                <div class="partnersMob">
                    <div className='p1'>
                    <img src={partner1}/>
                    <img src={partner3}/>
                    </div>

                    <div className='p2'>
                    <img src={partner4}/>
                    <img src={partner5}/>
                    </div>
                </div>

                <img class="logoF" src={logo} />
                <div class="iconsF">
                <img onClick={tweet} src={twitter} />
                <img onClick={telegL} src={teleg} />
                <img onClick={osL} src={os} />
                </div>

                <div class="copyright">Copyright © 2024 KONGI. All Rights Reserved</div>
            </div>

        </div>
        )
    }
}

export default Footer;

